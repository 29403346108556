import React from 'react';
import './iletisim.css';
import Footer from './footer';

function Iletisim() {
  return (
    <div className="iletisim-container">
      <div className="iletisim-content">
        <h1>İletişim</h1>
        <div className="iletisim-info">
          <p><strong>sigortasatinal.com bir Centalya Sigorta ARC. HİZ. LTD. ŞTİ. markasıdır.</strong></p>
          
          <p>Centalya Sigorta T.C. Başbakanlık Hazine Müsteşarlığı uygunluk belgesi sahibi ve T14114-G6KP levha numarası ile TOBB'a kayıtlıdır.</p>
          
          <h2>Şirket Bilgileri</h2>
          <p><strong>Ünvanı:</strong> Centalya Sigorta ARC. HİZ. LTD. ŞTİ.</p>
          <p><strong>Yetkili Kişi:</strong> Fatma Çakır</p>
          <p><strong>TOBB'a Kayıtlı Levha No:</strong> T14114-G6KP</p>
          
          <h2>İletişim Bilgilerimiz</h2>
          <p><strong>Adres:</strong>Kanal Mah. Yasemin Cad. Nil Apt No : 7/8 Antalya Kepez</p>
          <p><strong>Telefon:</strong> 0242 331 03 00</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Iletisim;