import React from 'react';
import './urunler.css';
import Footer from './footer';

function Urunler() {
  const products = [
    { 
      name: 'Trafik Sigortası', 
      description: 'Yasal zorunluluk, uygun fiyatlar', 
      isActive: true,
      link: 'https://www.sigortasatinal.com/trafik-sigortasi-teklif-al'
    },
    { 
      name: 'Kasko Sigortası', 
      description: 'Aracınızı her türlü riske karşı koruyun', 
      isActive: true,
      link: 'https://www.sigortasatinal.com/kasko-teklif-al'
    },
    { name: 'Konut Sigortası', description: 'Eviniz güvende, içiniz rahat', isActive: false },
    { name: 'Sağlık Sigortası', description: 'Sağlığınız için tam kapsamlı çözüm', isActive: false },
    { name: 'Seyahat Sigortası', description: 'Güvenli seyahatler için ideal çözüm', isActive: false },
    { name: 'İşyeri Sigortası', description: 'İşletmeniz için kapsamlı koruma', isActive: false },
  ];

  const handleButtonClick = (link) => {
    if (link) {
      window.location.href = link;
    }
  };

  return (
    <div className="urunler-container">
      <div className="urunler-content">
        <div className="urunler-header">
          <h1>Ürünlerimiz</h1>
        </div>
        
        <div className="urunler-list">
          {products.map((product, index) => (
            <div key={index} className={`product-card ${!product.isActive ? 'inactive' : ''}`}>
              <div className="product-header">
                <h2>{product.name}</h2>
              </div>
              <div className="product-content">
                <p className="product-description">{product.description}</p>
              </div>
              <div className="product-footer">
                {product.isActive ? (
                  <button 
                    className="product-button"
                    onClick={() => handleButtonClick(product.link)}
                  >
                    Teklif Al
                  </button>
                ) : (
                  <span className="coming-soon">YAKINDA</span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default Urunler;