import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import './App.css';
import TrafikSigortasiTeklifAl from './TrafikSigortasiTeklifAl';
import KaskoSigortasiTeklifAl from './KaskoSigortasiTeklifAl';
import Tesekkurler from './Tesekkurler';
import Urunler from './urunler';
import Hakkimizda from './hakkimizda';
import Iletisim from './iletisim';
import MobileMenu from './MobileMenu';
import Footer from './footer';
import allianzLogo from './images/allianzlogo.jpg';
import anadoluLogo from './images/anadolulogo.jpg';
import anaSigortaLogo from './images/anasigorta.jpg';
import dogaSigortaLogo from './images/dogasigorta.jpg';
import hdiSigortaLogo from './images/hdisigorta.jpg';
import hepiyiSigortaLogo from './images/hepiyisigorta.jpg';
import magdeburgerLogo from './images/magdeburgerlogo.jpg';
import neovaSigortaLogo from './images/neovasigorta.jpg';
import turkiyeSigortaLogo from './images/turkiyesigorta.png';
import akLogo from './images/aksigortalogo.jpg';
import axahayatlogo from './images/axahayatlogo.png';
import axalogo from './images/axalogo.jpg';
import turkiyehayatemeklilik from './images/turkiyehayatemeklilik.png';
import turkiyehayat from './images/turkiyehayat.png';
import somposigorta from './images/somposigorta.jpg';
import icon1 from './images/icon1.png';
import icon2 from './images/icon2.png';
import icon3 from './images/icon3.png';
import Menu from './Menu';
import rightImage from './images/right-image.jpg';
import KullanimKosullari from './KullanimKosullari';
import DaskForm from './dask';


function App() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const TrackPageView = () => {
    const location = useLocation();

    useEffect(() => {
      if (window.gtag) {
        window.gtag('config', 'AW-16665757756', {
          page_path: location.pathname,
        });
      }
    }, [location]);

    return null;
  };

  const logos = [
    { src: allianzLogo, alt: 'Sigorta Şirketi 2' },
    { src: akLogo, alt: 'Sigorta Şirketi 1' }, 
    { src: anadoluLogo, alt: 'Sigorta Şirketi 3' },
    { src: axalogo, alt: 'Sigorta Şirketi 11' },
    { src: axahayatlogo, alt: 'Sigorta Şirketi 13' },
    { src: anaSigortaLogo, alt: 'Sigorta Şirketi 4' },
    { src: dogaSigortaLogo, alt: 'Sigorta Şirketi 5' },
    { src: hdiSigortaLogo, alt: 'Sigorta Şirketi 6' },
    { src: hepiyiSigortaLogo, alt: 'Sigorta Şirketi 7' },
    { src: magdeburgerLogo, alt: 'Sigorta Şirketi 8' },
    { src: neovaSigortaLogo, alt: 'Sigorta Şirketi 10' },
    { src: somposigorta, alt: 'Sigorta Şirketi 12' },
    { src: turkiyehayatemeklilik, alt: 'Sigorta Şirketi 12' },
    { src: turkiyeSigortaLogo, alt: 'Sigorta Şirketi 14' },
    { src: turkiyehayat, alt: 'Sigorta Şirketi 14' },
  ];

  return (
    <Router>
      <TrackPageView />
      <div className="App">
        <Menu />
        <Routes>
          <Route path="/kullanim-kosullari" element={<KullanimKosullari />} />
          <Route path="/dask" element={<DaskForm />} />
          <Route path="/" element={
            <>
              <div className="gradient-background">
                <div className="slogan">
                  <h1 className="animated-text">En kapsamlısı, en uygunu.</h1>
                  <h2 className="marketing-text">Farklı sigorta şirketlerinden en uygun fiyatı almak için hemen teklif alın!</h2>
                </div>

                <div className="buttons">
                  <Link to="/trafik-sigortasi-teklif-al">
                    <button>Trafik Sigortası Teklifi Al</button>
                  </Link>
                  <Link to="/kasko-teklif-al">
                    <button>Kasko Teklifi Al</button>
                  </Link>
                </div>
                
                <div className="right-image-container">
                  <img src={rightImage} alt="Your Image Description" />
                </div>
              </div>
              
              <section className="why-us">
                <h2>Neden Biz?</h2>
                <p>Sunduğumuz hizmetlerle müşterilerimize en iyi sigorta deneyimini sağlıyoruz.</p>
                <div className="why-us-icons">
                  <div className="why-us-item">
                    <img src={icon1} alt="Güvenilirlik" />
                    <p>Güvenilir ve hızlı hizmet.</p>
                  </div>
                  <div className="why-us-item">
                    <img src={icon2} alt="Ekonomik Çözümler" />
                    <p>Ekonomik ve uygun fiyatlar.</p>
                  </div>
                  <div className="why-us-item">
                    <img src={icon3} alt="7/24 Destek" />
                    <p>7/24 müşteri desteği.</p>
                  </div>
                </div>
              </section>

              <section className="partners">
                <h2>Çalıştığımız Sigorta Şirketleri</h2>
                <div className="logos">
                  {logos.map((logo, index) => (
                    <img key={index} src={logo.src} alt={logo.alt} />
                  ))}
                </div>
              </section>

              <section className="testimonials">
                <h2>Müşteri Yorumları</h2>
                <div className="testimonial">
                  <p>"Geçen ay yaptırdığım trafik sigortası ve İMM poliçesiyle kendimi güvende hissediyorum. Süreç çok hızlı ilerledi ve en uygun fiyatı aldım. Müşteri hizmetleri her aşamada yardımcı oldu. Kesinlikle tavsiye ederim" - Ahmet Y.</p>
                </div>
                <div className="testimonial">
                  <p>"Kasko sigortamı bu şirketten yaptırdım ve çok memnun kaldım. Hem fiyat avantajı sundular hem de poliçeyi ihtiyaçlarıma göre özelleştirdiler. Herhangi bir hasar durumunda anında yanıt verdiler. Harikasınız" - Ayşe K.</p>
                </div>
                <div className="testimonial">
                  <p>"7/24 yol yardımı hizmeti gerçekten hayat kurtarıcı! Geçen hafta yolda kaldım ve bir telefonla hemen yardım aldım." - Mehmet T.</p>
                </div>
              </section>
              
              <Footer />
            </>
          } />
          <Route path="/trafik-sigortasi-teklif-al" element={<TrafikSigortasiTeklifAl />} />
          <Route path="/kasko-teklif-al" element={<KaskoSigortasiTeklifAl />} />
          <Route path="/tesekkurler" element={<Tesekkurler />} />
          <Route path="/urunler" element={<Urunler />} />
          <Route path="/hakkimizda" element={<Hakkimizda />} />
          <Route path="/iletisim" element={<Iletisim />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
