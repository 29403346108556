import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import MobileMenu from './MobileMenu';
import './TrafikSigortasiTeklifAl.css';
import DOMPurify from 'dompurify';

function TrafikSigortasiTeklifAl() {
  const [formData, setFormData] = useState({
    tcKimlikNo: '',
    plaka: '',
    ruhsatNo: '',
    telefon: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [isTermsAccepted, setIsTermsAccepted] = useState(true); // Checkbox varsayılan olarak işaretli
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    setIsTermsAccepted(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Tüm alanların dolu olduğunu kontrol et
    if (!formData.tcKimlikNo || !formData.plaka || !formData.ruhsatNo || !formData.telefon) {
      setErrorMessage('Tüm alanları doldurmalısınız.');
      return;
    }

    // Kullanım koşullarının kabul edildiğini kontrol et
    if (!isTermsAccepted) {
      setErrorMessage('Teklif almak için site kullanım koşullarını kabul etmelisiniz.');
      return;
    }

    // Verileri DOMPurify ile temizliyoruz
    const sanitizedData = {
      tcKimlikNo: DOMPurify.sanitize(formData.tcKimlikNo),
      plaka: DOMPurify.sanitize(formData.plaka),
      ruhsatNo: DOMPurify.sanitize(formData.ruhsatNo),
      telefon: DOMPurify.sanitize(formData.telefon),
    };

    try {
      console.log('Gönderilen veriler:', sanitizedData);

      const response = await fetch('https://sigortasatinal.com/process-form.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sanitizedData),
      });

      console.log('Sunucu yanıtı:', response);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Sunucu yanıtı (JSON):', result);

      if (result.success) {
        navigate('/tesekkurler');
      } else {
        setErrorMessage(result.message || 'Bir hata oluştu. Lütfen tekrar deneyiniz.');
      }
    } catch (error) {
      console.error('Hata:', error);
      setErrorMessage('Bir hata oluştu. Lütfen tekrar deneyiniz.');
    }
  };

  return (
    <div className="TrafikSigortasiTeklifAl">
      <div className="gradient-background">
        <header className="App-header">
          {/* Logo ve menü buraya eklenebilir */}
        </header>
        <div className="form-container">
          <h2>Trafik Sigortası Teklif Formu</h2>
          {/* Pazarlama mesajını buraya ekliyoruz */}
          <p className="marketing-message">Uygun fiyat almak için bilgilerinizi doldurun.</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="tcKimlikNo">TC Kimlik No:</label>
              <div className="input-wrapper">
                <span className="input-prefix">TC</span>
                <input
                  type="tel"
                  id="tcKimlikNo"
                  name="tcKimlikNo"
                  value={formData.tcKimlikNo}
                  onChange={handleChange}
                  required
                  maxLength="11"
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="plaka">Plaka:</label>
              <div className="input-wrapper">
                <span className="input-prefix">TR</span>
                <input
                  type="text"
                  id="plaka"
                  name="plaka"
                  value={formData.plaka}
                  onChange={handleChange}
                  required
                  maxLength="8"
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="ruhsatNo">Ruhsat No:</label>
              <div className="input-wrapper">
                <span className="input-prefix">RN</span>
                <input
                  type="tel"
                  id="ruhsatNo"
                  name="ruhsatNo"
                  value={formData.ruhsatNo}
                  onChange={handleChange}
                  required
                  maxLength="8"
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="telefon">Telefon Numarası:</label>
              <div className="input-wrapper">
                <span className="input-prefix">+90</span>
                <input
                  type="tel"
                  id="telefon"
                  name="telefon"
                  value={formData.telefon}
                  onChange={handleChange}
                  required
                  maxLength="11"
                />
              </div>
            </div>
            <div className="form-group checkbox-group">
  <label htmlFor="terms">
    <input
      type="checkbox"
      id="terms"
      name="terms"
      checked={isTermsAccepted}
      onChange={handleCheckboxChange}
    />
    <a href="/kullanim-kosullari" className="terms-link">
      Site kullanım koşullarını kabul ediyorum
    </a> 
  </label>
</div>

            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button type="submit">Teklif Al</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TrafikSigortasiTeklifAl;
