import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <div className="mobile-menu-container">
      <button onClick={toggleMenu} className="menu-toggle">
        {isOpen ? 'X' : '☰'}
      </button>
      {isOpen && (
        <nav className="mobile-menu">
          <ul>
            <li><Link to="/" onClick={toggleMenu}>Ana Sayfa</Link></li>
            <li><Link to="/hakkimizda" onClick={toggleMenu}>Hakkımızda</Link></li>
            <li><Link to="/iletisim" onClick={toggleMenu}>İletişim</Link></li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default MobileMenu;