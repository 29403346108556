import React from 'react';
import { Link } from 'react-router-dom';
import './Tesekkurler.css';

function Tesekkurler() {
  return (
    <div className="Tesekkurler">
      <div className="thank-you-container">
        <h2>Teşekkürler!</h2>
        <p>Teklif isteğiniz başarıyla alındı. En kısa sürede sizinle iletişime geçeceğiz.</p>
        <Link to="/" className="home-button">Ana Sayfaya Dön</Link>
      </div>
    </div>
  );
}

export default Tesekkurler;