import React from 'react';
import './hakkimizda.css';
import Footer from './footer';

function Hakkimizda() {
  return (
    <div className="hakkimizda-container">
      <div className="hakkimizda-content">
        <h1>Hakkımızda</h1>
        <div className="hakkimizda-section">
          <h2>Biz Kimiz?</h2>
          <p>
            sigortasatinal.com, Centalya Sigorta ARC. HİZ. LTD. ŞTİ. markasıdır. Centalya Sigorta, T.C. Başbakanlık Hazine Müsteşarlığı uygunluk belgesi sahibi olup T14114-G6KP levha numarası ile TOBB'a kayıtlıdır.
          </p>
          <p>
            <strong>Şirket Bilgileri:</strong><br />
            <strong>Ünvan:</strong> Centalya Sigorta ARC. HİZ. LTD. ŞTİ.<br />
            <strong>Yetkili Kişi:</strong> Fatma Çakır<br />
            <strong>TOBB'a Kayıtlı Levha No:</strong> T14114-G6KP
          </p>
          <p>
            <strong>İletişim Bilgilerimiz:</strong><br />
            <strong>Adres:</strong> Kanal Mah. Yasemin Cad. Nil Apt No : 7/8 Antalya Kepez <br />
            <strong>Telefon:</strong> 0242 331 03 00
          </p>
        </div>
        <div className="hakkimizda-section">
          <h2>Misyonumuz</h2>
          <p>
            Misyonumuz, müşterilerimizin hayatlarını ve varlıklarını koruyan, anlaşılması kolay ve uygun fiyatlı sigorta ürünleri sunmaktır.
          </p>
        </div>
        <div className="hakkimizda-section">
          <h2>Vizyonumuz</h2>
          <p>
            Vizyonumuz, Türkiye'nin en güvenilir ve tercih edilen sigorta şirketi olmaktır. Müşteri memnuniyeti ve yenilikçi çözümlerle sektöre öncülük etmeyi hedefliyoruz.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Hakkimizda;
