import React, { useState, useEffect } from 'react';
import './dask.css';

function DaskForm() {
    const [formData, setFormData] = useState({
        tcKimlikNo: '',
        dogumTarihi: '',
        cepTelefonu: '',
        binaMetrekaresi: '',
        binaKatSayisi: '',
        binaYasi: '',
        binaYapiTarzi: '',
        binaKullanimTarzi: '',
        binaHasarDurumu: '',
        il: '',
        ilce: ''
    });

    const [iller, setIller] = useState([]);
    const [ilceler, setIlceler] = useState([]);

    useEffect(() => {
        fetch('https://sigortasatinal.com/dask.php?action=getIller', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            console.log('API Response:', data);
            if (Array.isArray(data) && data.length > 0) {
                setIller(data.map(il => ({
                    IlKodu: il.IlKodu,
                    IlAdi: il.IlAdi
                })));
            } else if (data.error) {
                console.error('API Error:', data.error);
            } else {
                console.error('Unexpected data structure:', data);
            }
        })
        .catch(error => console.error('Fetch Error:', error));
    }, []);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch('https://sigortasatinal.com/dask.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...formData, action: 'calculatePolicy' }),
        });
        const result = await response.json();
        console.log(result);
    };
    

    return (
        <div className="dask-form-container">
            <h2>DASK Sigorta Başvuru Formu</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <input
                        type="text"
                        name="tcKimlikNo"
                        placeholder="TC Kimlik No"
                        value={formData.tcKimlikNo}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="date"
                        name="dogumTarihi"
                        placeholder="Doğum Tarihi"
                        value={formData.dogumTarihi}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        name="cepTelefonu"
                        placeholder="Cep Telefonu"
                        value={formData.cepTelefonu}
                        onChange={handleChange}
                        required
                    />
                    </div>
<div className="form-group">
    <select name="il" value={formData.il} onChange={handleChange} required>
        <option value="">İl Seçin</option>
        {iller.map((il) => (
            <option key={il.IlKodu} value={il.IlKodu}>
                {il.IlAdi}
            </option>
        ))}
    </select>
</div>
                <div className="form-group">
                    <input
                        type="number"
                        name="binaMetrekaresi"
                        placeholder="Bina Metrekaresi"
                        value={formData.binaMetrekaresi}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="number"
                        name="binaKatSayisi"
                        placeholder="Bina Kat Sayısı"
                        value={formData.binaKatSayisi}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="number"
                        name="binaYasi"
                        placeholder="Bina Yaşı"
                        value={formData.binaYasi}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        name="binaYapiTarzi"
                        placeholder="Bina Yapı Tarzı"
                        value={formData.binaYapiTarzi}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        name="binaKullanimTarzi"
                        placeholder="Bina Kullanım Tarzı"
                        value={formData.binaKullanimTarzi}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        name="binaHasarDurumu"
                        placeholder="Bina Hasar Durumu"
                        value={formData.binaHasarDurumu}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit">Teklif Al</button>
            </form>
        </div>
    );
}

export default DaskForm;
