import React from 'react';
import './footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>Hakkımızda</h3>
          <p><strong>sigortasatinal.com bir Centalya Sigorta ARC. HİZ. LTD. ŞTİ. markasıdır.</strong></p>
          <p>Centalya Sigorta T.C. Başbakanlık Hazine Müsteşarlığı uygunluk belgesi sahibi ve T14114-G6KP levha numarası ile TOBB'a kayıtlıdır.</p>
        </div>
        <div className="footer-section">
          <h3>Hızlı Bağlantılar</h3>
          <ul>
            <li><a href="anasayfa">Ana Sayfa</a></li>
            <li><a href="hakkimizda">Hakkımızda</a></li>
            <li><a href="urunler">Ürünler</a></li>
            <li><a href="iletisim">İletişim</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Şirket Bilgileri</h3>
          <p><strong>Ünvanı:</strong> Centalya Sigorta ARC. HİZ. LTD. ŞTİ.</p>
          <p><strong>Yetkili Kişi:</strong> Fatma Çakır</p>
          <p><strong>TOBB'a Kayıtlı Levha No:</strong> T14114-G6KP</p>
        </div>
        <div className="footer-section">
          <h3>İletişim Bilgilerimiz</h3>
          <p><strong>Adres:</strong> Kanal Mah. Yasemin Cad. Nil Apt No : 7/8 Antalya Kepez </p>
          <p><strong>Telefon:</strong> 0242 331 03 00</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Centalya Sigorta ARC. HİZ. LTD. ŞTİ. Tüm hakları saklıdır.</p>
      </div>
    </footer>
  );
}

export default Footer;