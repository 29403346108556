import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom'; // React Router kullanıyorsanız
import centalyaLogo from './images/centalya.webp';
import MobileMenu from './MobileMenu';

function Menu() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <header className="App-header">
      <div className="logo">
        <a href="https://www.sigortasatinal.com" target="_blank" rel="noopener noreferrer">
          <img src={centalyaLogo} alt="Centalya Sigorta" />
        </a>
      </div>
      {isMobile ? (
        <MobileMenu />
      ) : (
        <nav className="desktop-menu">
          <ul>
            <li><Link to="/">Ana Sayfa</Link></li>
            <li><Link to="/urunler">Ürünler</Link></li>
            <li><Link to="/hakkimizda">Hakkımızda</Link></li>
            <li><Link to="/iletisim">İletişim</Link></li>
          </ul>
        </nav>
      )}
    </header>
  );
}

export default Menu;